<template>
  <div class="py-8 pb-10" v-if="donationList[0]">
    <v-container>
      <v-row>
        <v-col>
          <div class="heading">
            Recent Donations
            <div class="hLine"></div>
          </div>
        </v-col>
      </v-row>
    </v-container>
    <v-sheet class="mx-auto" max-width="100%" color="#f7f7f7">
      <v-slide-group multiple :show-arrows="$vuetify.breakpoint.smAndUp">
        <template v-slot:prev>
          <v-icon color="#D30024">mdi-chevron-left</v-icon>
        </template>
        <template v-slot:next>
          <v-icon color="#D30024">mdi-chevron-right</v-icon>
        </template>
        <v-slide-item
          v-for="(item, i) in donationList"
          :key="i"
          :class="$vuetify.breakpoint.smAndUp ? 'ma-8' : 'ma-4'"
        >
          <v-card
            :min-width="$vuetify.breakpoint.smAndUp ? '360px' : '100px'"
            :min-height="$vuetify.breakpoint.smAndUp ? '170px' : '100px'"
            class="py-4 px-2"
          >
            <v-card-title>
              <v-row>
                <v-col cols="4" class="d-flex">
                  <v-sheet
                    color="rgba(56, 167, 55, 0.12)"
                    class="px-2"
                    width="80px"
                    height="35"
                    style="margin: auto"
                  >
                    <p
                      style="
                        color: #38a737;
                        width:75px;
                        display: inline-block;
                        text-overflow: ellipsis !important;
                        white-space: nowrap;
                        overflow: hidden !important;
                      "
                    >
                      ${{ item.original_amount }}
                    </p>
                  </v-sheet>
                </v-col>
                <v-col>
                  <div
                    style="
                      color: #1d1d1d;
                      font-weight: bold;
                      font-size: 22px;
                      line-height: 100%;
                    "
                  >
                    {{ item.donor_name }}
                  </div>
                  <div
                    style="font-size: 12px; color: #747474; line-height: 130%"
                  >
                    {{ item.days_before }} days ago
                  </div>
                </v-col>
              </v-row>
            </v-card-title>
            <v-card-text class="pt-0">
              <div style="width: 280px; font-size: 16px; color: #2d2d2d">
                {{ item.donor_special_message }}
              </div>
            </v-card-text>
          </v-card>
        </v-slide-item>
      </v-slide-group>
    </v-sheet>
  </div>
</template>
<script>
import Axios from "@/api/BaseAxios";
import { mapGetters } from "vuex";
import { API_PUBLIC_RECENT_DONATION } from "@/constants/APIUrls";
export default {
  name: "RecentDonation",
  data() {
    return {
      donationList: [],
      date: new Date(),
    };
  },
  computed: {
    ...mapGetters({
      getCampaign: "publicDonationPage/getCampaign",
    }),
    getcampaignType() {
      return this.getCampaign.type;
    },
    ids() {
      let panel_id = "";
      if (this.getcampaignType.type === "student") {
        panel_id = this.getcampaignType.student_id;
      } else if (this.getcampaignType.type === "teacher") {
        panel_id = this.getcampaignType.teacher_id;
      }
      return panel_id;
    },
  },
  methods: {
    getDonationDetails() {
      const successHandler = (res) => {
        this.donationList = res.data.donation_list;
        // console.log(res.data.donation_list, "don");
      };
      const failureHandler = (res) => {
        console.log(res);
      };
      let formData = {};
      formData["campaign_id"] = this.getCampaign.campaign_detail.id;
      formData[this.getcampaignType.type + "_id"] = this.ids;
      Axios.request_GET(
        API_PUBLIC_RECENT_DONATION,
        formData,
        {},
        successHandler,
        failureHandler,
        false
      );
    },
  },
  mounted() {
    // console.log(this.getCampaign, "fffffffff");
    this.getDonationDetails();
  },
};
</script>
<style scoped>
* {
  font-family: Lato;
}
.heading {
  font-size: 25px;
  font-family: Roboto Slab;
  font-weight: 600;
  color: #2c1963;
  display: flex;
}
.hLine {
  background-color: #2c1963;
  width: 50px;
  height: 4px;
  border-radius: 20px;
  margin-top: auto;
  margin-bottom: auto;
  margin-left: 15px;
}
.v-card {
  background: #fafafa;
  border: 1px solid #dddddd;
  box-sizing: border-box;
  box-shadow: 2px 4px 4px rgba(0, 0, 0, 0.15);
  border-radius: 14px;
}
@media only screen and (max-width: 600px) {
  .heading {
    font-size: 20px;
  }
  .hLine {
    background-color: #2c1963;
    width: 30px;
    height: 3px;
    margin-top: 15px;
  }
}
</style>
